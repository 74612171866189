//
//
//


export default {
  name: 'base-page-meta-schema',
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    robots: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    meta: {
      type: Array,
      default: () => [],
    },
  },
  head() {
    const meta = [
      { hid: 'robots', name: 'robots', content: this.robots || (process.env.VUE_APP_ENV === 'production' ? 'index, follow' : 'none') },
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:site', name: 'twitter:site', content: '@MotionElements' },
      { hid: 'twitter:creator', name: 'twitter:creator', content: '@MotionElements' },
      { hid: 'twitter:url', name: 'twitter:url', content: this.canonicalUrl },
      { hid: 'twitter:image:src', name: 'twitter:image:src', content: this.ogImage },
      { hid: 'og:site_name', property: 'og:site_name', content: 'MotionElements' },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:locale', property: 'og:locale', content: this.ogLocale },
      { hid: 'og:url', property: 'og:url', content: this.canonicalUrl },
      { hid: 'og:image', property: 'og:image', content: this.ogImage },
      { hid: 'og:image:secure_url', property: 'og:image:secure_url', content: this.ogImage },
    ];

    if (this.title) {
      meta.push(
        { hid: 'twitter:title', name: 'twitter:title', content: this.title },
          { hid: 'og:title', property: 'og:title', content: this.title },
      );
    }

    if (this.description) {
      meta.push(
        { hid: 'description', name: 'description', content: this.description },
          { hid: 'twitter:description', name: 'twitter:description', content: this.description },
          { hid: 'og:description', property: 'og:description', content: this.description },
      );
    }

    if (this.meta.length) {
      meta.concat(this.meta);
    }
    return {
      // set `undefined` to use parent title, https://vue-meta.nuxtjs.org/guide/special.html#use-child-property-conditionally
      title: this.title || undefined,
      // set `undefined` to use parent description
      description: this.description || undefined,
      // titleTemplate: '%s | MotionElements',
      link: this.hreflangLinks,
      meta: meta,
      // __dangerouslyDisableSanitizers: ['script'], // do not escape json+ld script
      __dangerouslyDisableSanitizersByTagID: {
        // do not escape '&' in xxx.jpg?fm=pjpg&auto=format&fit=crop&w=1200&h=630 to '&amp;'
        'og:image': ['content'],
        'og:image:secure_url': ['content'],
        'twitter:image': ['content'],
      },
      // script: this.getPageSchema(),
    };
  },
  computed: {
    ogImage() {
      const languageSuffix = ['en', 'ja', 'ko', 'zh-hant'].includes(this.$i18n.locale) ? this.$i18n.locale : 'en';
      return this.getImgixOgImageUrl(this.image || `https://static.moele.me/img/motionelements-og-${languageSuffix}.220830.jpg`);
      // return this.getImgixOgImageUrl(this.image || 'https://static.moele.me/img/MotionElements-logo-og-white.190911.jpg');
    },
    ogLocale() {
      switch (this.$i18n.locale) {
        case 'zh-hant':
          return 'zh_TW';
        case 'zh-hans':
          return 'zh_CN';
        case 'ja':
          return 'ja_JP';
        case 'es':
          return 'es_ES';
        case 'th':
          return 'th_TH';
        case 'ko':
          return 'ko_KR';
        case 'de':
          return 'de_DE';
        case 'fr':
          return 'fr_FR';
        case 'pt':
          return 'pt_PT';
        case 'hu':
          return 'hu_HU';
        case 'it':
          return 'it_IT';
        case 'ru':
          return 'ru_RU';
        case 'tr':
          return 'tr_TR';
        case 'sv':
          return 'sv_SE';
        case 'pl':
          return 'pl_PL';
        case 'nb':
          return 'nb_NO';
        case 'nl':
          return 'nl_NL';
        case 'da':
          return 'da_DK';
        default:
          return 'en_US';
      }
    },
    ogImageLang() {
      switch (this.$i18n.locale) {
        case 'ja':
        case 'ko':
        case 'zh-hant':
          return this.$i18n.locale;
        default:
          return 'en';
      }
    },
    normalizedPath() {
      return this.$link(this.$route.path, 'en').fullPath();
    },
    canonicalUrl() {
      // const path = this.normalizedPath;
      const path = this.$route.path;
      return `https://www.motionelements.com${path}`;
    },
    hreflangLinks() {
      //   return [
      //     { rel: 'canonical', href: `https://www.motionelements.com/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}` },
      //     { rel: 'alternate', hreflang: 'x-default', href: 'https://www.motionelements.com/' },
      //     { rel: 'alternate', hreflang: 'en', href: 'https://www.motionelements.com/' },
      //     { rel: 'alternate', hreflang: 'ja', href: 'https://www.motionelements.com/ja/' },
      //   ];

      const path = this.normalizedPath;

      const data = [
        { rel: 'canonical', href: this.canonicalUrl },
        { rel: 'alternate', hreflang: 'x-default', href: `https://www.motionelements.com${path}` },
        { rel: 'alternate', hreflang: 'en', href: `https://www.motionelements.com${path}` },
      ];

      // languageCodes without en
      const languageCodes = process.env.VUE_APP_LANGUAGE_CODES ? process.env.VUE_APP_LANGUAGE_CODES.replace('en,', '').split(',') : [];

      languageCodes.forEach((languageCode) => {
        // convert to hrefLang code
        let hrefLanguageCode;

        switch (languageCode) {
          case 'zh-hant':
            hrefLanguageCode = 'zh-Hant-TW';
            break;
          case 'zh-hans':
            hrefLanguageCode = 'zh-Hans';
            break;
          default:
            hrefLanguageCode = languageCode;
        }

        data.push({
          rel: 'alternate', hreflang: hrefLanguageCode, href: `https://www.motionelements.com/${languageCode}${path}`,
        });
      });
      return data;
    },
  },
  methods: {
    getImgixOgImageUrl(imageUrl) {
      // ?auto=format&fit=crop&w=1200&h=630'
      const url = new URL(imageUrl);
      url.searchParams.set('auto', 'format');
      url.searchParams.set('fit', 'crop');
      url.searchParams.set('w', 1200);
      url.searchParams.set('h', 630);
      return (
        `https://${
          url.hostname
        }${url.pathname
        }${url.search
        }`
      );
    },
  },
};
